import * as React from "react";
import { Link, graphql } from "gatsby";
import {
  Layout,
  SEO,
  CJournalJumbotron,
  CBreadCrumb,
  CJournalLead,
  CProfileMedia,
  CJournalText,
  CWideImgMedia,
  CFloatingImg,
  CBtnList,
  LWrap,
  CQuoteBox,
  CSingleImg,
  CSingleImg02,
  CChapterHeading,
  CLabelTextList,
  CJournalMedia,
  CMedia,
  CTileImg,
  AssetImage,
} from "../../../components/_index";
import { text } from "stream/consumers";

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  console.log(data.markdownRemark);
  return (
    <Layout isKv={false}>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CBreadCrumb
        data={{
          parent: [
            {
              label: "pickup",
              path: "/pickup/",
            },
          ],
          current: {
            label: frontmatter?.title.includes("-")
              ? frontmatter?.title.split("-")[0]
              : frontmatter?.title,
          },
        }}
      />
      <CJournalJumbotron
        data={{
          label: "ザ ロイヤルパークホテル 広島リバーサイド",
          date: "2024年03月29日",
          title: (
            <>
              観光もスポーツ観戦も！
              <br className="u_sp"/>
              広島の魅力を再発掘する“推し活旅”へ
            </>
          ),
          text: "ザ ロイヤルパークホテル 広島リバーサイド",
          img: {
            src: "/assets/images/journal/article10/kv.png",
          },
          imgSp: {
            src: "/assets/images/journal/article10/kv__sp.png",
          },
        }}
      />
      <div className="u_pb60">
        <LWrap size="small">
          <CJournalLead
            lead={
              <>
                あたたかさに包まれる春は、どこか遠くへ旅したい。街歩きに、観光、おいしいものめぐり…あるいは思いっきり声をあげて声援を送るスポーツ観戦もありかも？大好きなスポーツチーム=“推し”のためなら、いつもの旅がまた一味違ったものに！そんな元気をもらえる“推し活旅”を、ザ ロイヤルパークホテル 広島リバーサイドからめぐってみた。
              </>
            }
          />
        </LWrap>
      </div>
      <section className="u_mbLarge">
        <CChapterHeading
          data={{
            label: {
              en: "CHAPTER",
              number: "01",
            },
            title: (
              <>
                <span className="u_pr0_pc">広島でスポーツ観戦</span>
                <span>×推し活旅!?</span>
              </>
            ),
          }}
        />
        <LWrap>
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                  広島と聞いて思い浮かぶものは？お好み焼きや牡蠣、もみじ饅頭などのグルメに、戦争の歴史を学ぶ原爆ドームや平和記念公園、宮島の厳島神社、または絶景島旅を味わう瀬戸内…。定番も捨て難いけれど、今回の旅のお目当ては、大好きなスポーツチーム「広島東洋カープ」を応援すること。そう、最近巷で流行りの“推し活”がメインイベントなのだ。
                  </p>
                  <p>
                  実は今、広島のスポーツ熱が高まっている。言わずと知れたカープの本拠地であるとともに、サッカーやバスケットボール、バレーボールなどのプロチームもあり、さらには2024年2月に巨大なサッカースタジアムがオープンするなど、市内のあちこちでスポーツの話題が目白押し！そんなスポーツの街、広島の魅力を再発掘するため、さっそく広島駅から出発しよう。
                  </p>
                </>
              ),
            }}
          />
          <figure className="imgWrapper">
            <CSingleImg02
              img={{
                src: "/assets/images/journal/article10/icon01.png",
              }}
              imgSp={{
                src: "/assets/images/journal/article10/icon01__sp.png",
              }}
            />
          </figure>
          <CSingleImg
            img={{
              src: "/assets/images/journal/article10/img_chapter01_01.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    やってきたのは広島城址公園内にある広島護國神社。
                    <br />
                    ここは戊辰戦争以降、第二次世界大戦までの戦争や原爆で亡くなった兵士たちが祀られていることで知られているが、実は毎年カープ球団が必勝祈願へ訪れることでも有名。
                    <br />
                    境内には、本殿に向かって左側に双鯉の像、右側に昇鯉の像が鎮座している。カープは日本語で「鯉」のこと。聞いた話によると広島城の別名「鯉城（りじょう）」から名づけられた球団名とも言われているらしく、なにかと推しチームと縁が深い！
                  </p>
                  <p>
                    「明日の試合、どうか勝ちますように！」
                  </p>
                  <p>
                    はるばる広島までやってきたのだから、明日の試合では勝ち姿をみて帰りたい。そんな願いを込めて、本殿で手を合わせる。
                  </p>
                </>
              ),
            }}
          />
          <CTileImg
            size="small"
            data={[
              {
                img: {
                  src: "/assets/images/journal/article10/img_chapter01_02.png",
                  alt: "",
                },
              },
              {
                img: {
                  src: "/assets/images/journal/article10/img_chapter01_03.png",
                  alt: "",
                },
              },
            ]}
            caption={
              <>
                鯉が泳いでいるようなキュートなおみくじは、持ち帰って飾るもよし。お守りは勝守以外にも種類が豊富
              </>
            }
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    参拝後には、選手たちにも毎年渡されるという勝守と鯉を模した可愛らしいおみくじを手に。
                  </p>
                  <p>
                    「選手とお揃いのお守り、これ欲しかったんだよね」
                    <br />
                    「明日の運勢は…（どきどき）！」
                  </p>
                  <p>
                    チームゆかりの地を訪れながら観光もできてしまう、これぞ“推し活旅”の醍醐味だろう。
                  </p>
                  <p>
                    広島護國神社
                    <br />
                    住所：広島市中区基町21-2
                    <br />
                    HP：<a href="https://www.h-gokoku.or.jp" target="_blank">https://www.h-gokoku.or.jp</a>
                  </p>
                </>
              ),
            }}
          />
          <figure className="imgWrapper">
            <CSingleImg02
              img={{
                src: "/assets/images/journal/article10/icon02.png",
              }}
              imgSp={{
                src: "/assets/images/journal/article10/icon02__sp.png",
              }}
            />
            <CJournalText
            data={{
              text: (
                <>
                  参拝を終えると時刻は昼過ぎ。チェックインにはまだ少し早いから、周辺を散策。2024年2月1日に開業したばかりの注目スポット「エディオンピースウイング広島」がすぐ隣にあると聞きつけ、行ってみることに。
                  <br />
                  歩きはじめてすぐ、青空に羽ばたくような大きな翼を模した屋根が特徴的なスタジアムが見えてきた。
                </>
              ),
            }}
          />
          </figure>
          <CSingleImg
            img={{
              src: "/assets/images/journal/article10/img_chapter01_04.png",
              alt: "",
            }}
            caption={<>平和と明るい未来への願いを込めて、翼（ウイング）をイメージして設計されている</>}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    ここは、日本初となるまちなかにある“都心交流型スタジアムパーク”。サッカーチーム、サンフレッチェ広島の新たな本拠地として試合が開催されるそう。今夏には、隣接する公園「ひろしまスタジアムパーク」も完成して、キッズプレイパークやライブラリーカフェ、屋台縁日などのオープンも予定されている。
                  </p>
                  <p>
                    施設の中には、体験型の広島サッカーミュージアムやサンフレッチェ広島のクラブグッズ、そして広島県の特産品が手に入るピースウイングオフィシャルストア。さらには世界をテーマにしたスタジアムグルメまで見どころがたくさん。
                  </p>
                  <p>
                  「推し選手のオリジナルグッズが作れるみたい、これも可愛いね」
                  <br />
                  「選手とのコラボメニューが食べられるの？すごい！」
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article10/img_chapter01_05.png",
              alt: "",
            }}
            caption={
              <>
                サッカーミュージアム内観 ©S.FC
              </>
            }
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    ちょっと立ち寄ってみるだけでも、満足度は120%！スポーツ観戦以外でも楽しめる要素があって、これは新たな観光ポイントになること間違いなし。
                  </p>
                  <p>
                    エディオンピースウイング広島
                    <br />
                    住所：広島市中区基町15-2-1
                    <br />
                    HP：<a href="https://www.hiroshima-stadiumpark.jp/peacewing/" target="_blank">https://www.hiroshima-stadiumpark.jp/peacewing/</a>
                  </p>
                </>
              ),
            }}
          />
        </LWrap>
      </section>

      <section className="u_mbLarge">
        <CChapterHeading
          data={{
            label: {
              en: "CHAPTER",
              number: "02",
            },
            title: (
              <>
                <span className="u_pr0_pc">ホテルステイは優雅に、</span>
                <span className="u_pr0_pc">リバーサイドをまったり</span>
                <span>楽しむ</span>
              </>
            ),
          }}
        />
        <LWrap>
          <figure className="imgWrapper">
            <CSingleImg02
              img={{
                src: "/assets/images/journal/article10/icon03.png",
              }}
              imgSp={{
                src: "/assets/images/journal/article10/icon03__sp.png",
              }}
            />
          </figure>
          <CJournalText
            data={{
              text: (
                <>
                  観光を終えたら、チェックイン時間を目掛けてホテルへ。今回宿泊するのは、広島駅から徒歩8分ほどにあるザ ロイヤルパークホテル 広島リバーサイド。広島市内を流れる京橋川沿いにあり、静かな街に溶け込むリバーサイドの眺めが楽しめる。
                  <br />
                  アクティブな旅だけれど、しっかり身体は休めたいし、予定をあまり詰めすぎずホテルでゆっくりする時間も大切にしたい。だからこそ、今回の宿はこのリバービューを求めてここに決めたのだ。
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article10/img_chapter02_01.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  広島駅から徒歩圏内にありながら、この落ち着いた雰囲気にひたりつつ、少し歩けば繁華街もあるので、広島の夜を楽しみたいという人にもぴったり。
                </>
              ),
            }}
          />
          <CTileImg
            size="small"
            data={[
              {
                img: {
                  src: "/assets/images/journal/article10/img_chapter02_02.png",
                  alt: "",
                },
              },
              {
                img: {
                  src: "/assets/images/journal/article10/img_chapter02_03.png",
                  alt: "",
                },
              },
            ]}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    ホテルの中に入ると、広々としたロビースペースが。ふとその一角に目をやると、折り紙で作られた鶴に、鳥に、パンダ、それから…。
                  </p>
                  <p>
                    「あっ！これ野球帽とボールじゃない？すごくよくできてるね」
                  </p>
                  <p>
                    スタッフが季節ごとにモチーフを選んで一つひとつ手作りしているという折り紙飾りは、ホテルのささやかなおもてなし。到着早々「このホテルにしてよかった」と居心地の良さを感じる。
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article10/img_chapter02_04.png",
              alt: "",
            }}
            caption={
              <>
                ピンク以外にパステルブルーの客室も。部屋タイプにより内装は異なるから、泊まる人数やシチュエーションによって変化を楽しめる
              </>
            }
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    「わぁ！かわいい」
                  </p>
                  <p>
                    思わず声を上げたくなるのは、客室に入った瞬間に目に飛び込んでくるパステルカラー。淡く可愛らしいピンク色の壁やウッド調の家具、アートにいたるまで優しく愛らしい雰囲気の中に、洗練された大人っぽさも感じる。窓からは目の前の京橋川と市内の街並みをのぞむ景色で、コンパクトながら快適に過ごせそう。
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article10/img_chapter02_05.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    「さあ、明日の準備に取り掛かろう！」
                  </p>
                  <p>
                    そう言って鞄から取り出したのは、応援グッズ。試合のために持参した推し活グッズをベッドに広げてみると、明るい部屋のカラーと相まって可愛い！お気に入りのグッズたちに気分も上がり即興の撮影大会に。
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article10/img_chapter02_06.png",
              alt: "",
            }}
            caption={<>ホテルで過ごす時間も旅の一部。写真を撮って思い出に残したい</>}
          />
          <CJournalText
            data={{
              text: (
                <>
                  グッズを広げて、部屋でくつろぎながら、明日の試合どんなかな？なんて会話も弾みそう。
                </>
              ),
            }}
          />
        </LWrap>
      </section>

        <section className="u_mbLarge">
        <CChapterHeading
          data={{
            label: {
              en: "CHAPTER",
              number: "03",
            },
            title: (
              <>
                <span className="u_pr0_pc">朝食でパワーチャージ！</span>
                <span>いざスポーツ観戦へ</span>
              </>
            ),
          }}
        />
        <LWrap>
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    広島、二日目の朝。部屋に差し込む心地よい朝日で、すっきりと目が覚めた。
                    <br />
                    スポーツ観戦は意外と体力を使うから、しっかり朝ごはんでパワーチャージをしたい。そこでやってきたのは、ホテル1Fのレストラン「ザ リバーサイドカフェ」。ここでは、ブッフェ形式で朝食が楽しめる。
                  </p>
                  <p>
                    開放感あふれる大きな窓からは、テラス席越しにリバービューが。遊歩道を散歩する人やランニングする人を横目に優雅な朝食時間にひたろう。
                  </p>
                </>
              ),
            }}
          />
          <CTileImg
            size="small"
            data={[
              {
                img: {
                  src: "/assets/images/journal/article10/img_chapter03_01.png",
                  alt: "",
                },
              },
              {
                img: {
                  src: "/assets/images/journal/article10/img_chapter03_02.png",
                  alt: "",
                },
              },
            ]}
            caption={
              <>
                ホテル目の前は桜並木なので、春には桜とリバービューの絶景が楽しめる
              </>
            }
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    朝の気分を上げてくれるのは、レストランの雰囲気だけではない。ご当地グルメも楽しめるブッフェメニューは、広島ならではのお好み焼きやあなごだし茶漬け、もみじ饅頭など約40種類がずらりと並ぶ。
                  </p>
                  <p>
                    「やっぱりお好み焼きは外せないよね！」
                    <br />
                    「瀬戸内のレモンバターも試してみようかな」
                  </p>
                  <p>
                    あれもこれもと選んでいたら、ついつい食べすぎてしまいそう！やっぱり、旅にきたのならその土地を感じられる食は楽しみたい。お腹も心も満たされる最高の朝だ。
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article10/img_chapter03_03.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    「気持ちがいいし、ちょっとホテル周辺を歩いてみない？」
                  </p>
                  <p>
                    どちらからともなく、声を掛け合い心地よいリバーサイド散歩へ繰り出す。朝日に反射してキラキラと水面が輝く川を横目に、朝のフレッシュな空気を胸いっぱいに吸い込んで。
                  </p>
                  <p>
                    「いっぱい食べたから、散歩するのがちょうどいいね」
                    <br />
                    「このまま歩いて球場まで行っちゃう！？」
                  </p>
                  <p>
                    準備は万端。ホテルをチェックアウトしたら、さあいよいよマツダスタジアムへ！
                  </p>
                </>
              ),
            }}
          />
          <figure className="imgWrapper">
            <CSingleImg02
              img={{
                src: "/assets/images/journal/article10/icon04.png",
              }}
              imgSp={{
                src: "/assets/images/journal/article10/icon04__sp.png",
              }}
            />
          </figure>
          <CJournalText
            data={{
              text: (
                <>
                  スタジアムへの道中には、通称“カープロード”と呼ばれるスポットもあり、本拠地ならではの雰囲気を味わえる。足を止め、写真に収めつつ向かうのもいいかも。
                </>
              ),
            }}
          />
          <CTileImg
            size="small"
            data={[
              {
                img: {
                  src: "/assets/images/journal/article10/img_chapter03_04.png",
                  alt: "",
                },
              },
              {
                img: {
                  src: "/assets/images/journal/article10/img_chapter03_05.png",
                  alt: "",
                },
              },
            ]}
            caption={
              <>
                左）球団創立70周年を記念したモニュメント。右）球場周辺にはカープのマスコットキャラクター「カープ坊や」のマンホールが
              </>
            }
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    「ほら、あれじゃない？」
                    <br />
                    「ほんとだ、着いたー！！」
                  </p>
                  <p>
                    ホテルから川沿い、そして市内の中心部を歩くこと20分ほど。お目当てのマツダスタジアムが見えてきた。レンガ色の球場は、カープの本拠地として街の人々から愛されているスポット。
                  </p>
                  <p>
                  「今日の記念に、1枚！」
                  </p>
                  <p>
                    持参した推し活グッズを身につけて記念写真を。
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article10/img_chapter03_06.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                  試合までまだ少し時間があるので、スタジアム1F の公式グッズショップへ立ち寄ることに。ショップには数百点ものグッズが並び、選手の名前が書かれたフェイスタオルやユニフォーム、キーホルダーに応援グッズ、コラボグッズも目白押し。
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article10/img_chapter03_07.png",
              alt: "",
            }}
            caption={
              <>
                ショップ内の一角には、選手直筆のサインも。選手たちのロッカーを模したディスプレイとともに写真に収めておきたい
              </>
            }
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    「今日もいっぱい買っちゃった」
                    <br />
                    「ついつい集めちゃうよね」
                  </p>
                  <p>
                    毎年新シーズンに合わせて新作も出るので、来るたびについつい買い集めてしまうのもまた推し活の楽しみだ。
                  </p>
                  <p>
                    ちなみに、ショップ2F には2023年3月にオープンしたカープギャラリーも。リーグ優勝・日本一のトロフィーや歴代の球団ユニフォーム、現役選手が使用していた用具などここでしかみられない貴重な品々が展示されている。
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article10/img_chapter03_08.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    いよいよプレーボールの時間を迎えて、球場内の熱気も一気に上がる！球場は真っ赤に染まり、一面カープカラーに！たくさんの人が声援を送る中、負けじと大きな声で応援する。
                  </p>
                  <p>
                    「声を出して応援なんていつぶりかな、気持ちいい！」
                    <br />
                    「これこそスポーツ観戦の醍醐味だよね」
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article10/img_chapter03_09.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    「あぁ、楽しかった。最高にリフレッシュできた！」
                  </p>
                  <p>
                    手に汗握る試合は、やっぱり現地観戦でしか味わえない高揚感があるもの。勝っても負けても楽しさは同じ。球場の熱い雰囲気を楽しむだけでも十分なのだ。
                  </p>
                  <p>
                    Mazda Zoom-Zoom スタジアム広島（マツダスタジアム）
                    <br />
                    住所：広島市南区南蟹屋2-3-1
                    <br />
                    HP：<a href="http://www.mazdastadium.jp" target="_blank">http://www.mazdastadium.jp</a>
                  </p>
                </>
              ),
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    観光もグルメも満喫しつつ、ホテルで優雅にくつろぐ時間もあったりと充実の2日間。“推し活旅”って意外とメリハリがあってハマっちゃうかも！
                  </p>
                  <p>
                    「次は、サッカー観戦もいいかもね」
                    <br />
                    「私もそれ言おうと思ってた！…紫色のユニフォーム着て、サッカー応援！」
                  </p>
                  <p>
                    早くも次の“推し活旅”へと気持ちが向いているのは、きっとこの旅が充実していたから。
                    <br />
                    次はどんなアクティブな旅が広島で楽しめるのか、期待に胸を膨らませ今回の旅は終了。
                    <br />
                    リフレッシュできて、最高にパワーをもらえる“推し活旅”！
                    <br />
                    この春は、あなたも旅で元気をもらいませんか？ 
                  </p>
                </>
              ),
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <ul className="c_noteList">
                    <li>本記事・画像は2024年3月時点のものです。</li>
                  </ul>
                </>
              ),
            }}
          />

          <p className="u_tac u_fs20 u_mbSmall">ザ ロイヤルパークホテル<br className="u_sp"/> 広島リバーサイド おすすめプラン</p>
          <CQuoteBox>
            <h3 className="c_headingLv3 u_tac">
              THE ステイ【朝食付】
            </h3>
            <CBtnList
            exClass="u_mt20"
            data={[
              {
                label: "プラン詳細",
                color: "bgBlack",
                link: {
                  href: "https://rsv.ihonex.com/cgi-bin/ihonex3/plan_shokai.cgi?hid=rphs_thehiroshima&plan_groupcd=H01B&form=jp&_ga=2.137565554.357982208.1711356679-1175140856.1680138157&_gl=1*ul11e2*_ga*MTE3NTE0MDg1Ni4xNjgwMTM4MTU3*_ga_TYG7MVFL88*MTcxMTU5MDY3OS40OTIuMS4xNzExNTkwOTUwLjYwLjAuMA..*_ga_RFEE95BS4P*MTcxMTU5MDY3OS42Ni4xLjE3MTE1OTA3NDUuNjAuMC4w",
                  blank: true,
                },
                icon: "blank",
              },
            ]}
          />
          </CQuoteBox>

          <p className="u_fwb u_tac u_mt80">ザ ロイヤルパークホテル 広島リバーサイド</p>
          <CBtnList
            exClass="u_mt20"
            data={[
              {
                label: "ホテルサイト",
                color: "bgBlack",
                link: {
                  href: "https://www.royalparkhotels.co.jp/the/hiroshimars/",
                  blank: true,
                },
                icon: "blank",
              },
            ]}
          />
        </LWrap>
      </section>

      <div className="l_sect02 u_bgGray">
        <LWrap>
          <CBtnList
            data={[
              {
                label: "ロイヤルパークホテルズJournal",
                link: {
                  href: "/pickup/#journal",
                },
                iconBefore: true,
                icon: "arrowReverse",
              },
            ]}
          />
        </LWrap>
      </div>
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
